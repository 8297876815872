* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navbar {
  width: 100vw;
  max-width: 1920px; /*se limita a un maximo de 1920px de ancho*/
  height: 70px;
  position: fixed;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: stretch;
  align-items: center;
  background-color: rgb(23, 45, 143);
}

.left {
  flex: 50%;
  text-align: left;
  padding-left: 50px;
}

.right {
  flex: 40%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  padding-right: 5px;
}

.logo {
  padding-top: 3px;
  border-radius: 10%;
}

.ul {
  list-style: none;
  padding: 5px;
  margin: 5px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content:space-evenly;
}

.li {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
}


.link {
  text-decoration: none;
  color:white
}

.link:hover {
  transition-duration: 0.5s;
  color:dodgerblue;
}

.boton {
  display: none;
} 


@media screen and (max-width: 860px) {


  .left {
    flex: 30%;
    padding-left: 10px;
  }
  
  .right {
    flex: 70%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    padding-right: 1px;
  }

  .ul {
    display: none;

  }

  .li {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.0rem;
    font-weight: bold;
  }

  .boton {
    display: block;
    margin-right: 10px;
  } 

 #hidden {
    position: absolute;
    left: -5px;
    top:75px;
    height: 150px;
    max-height: 150px;
    width: 100%;
    background-color: darkblue;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
}
