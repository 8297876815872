* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.contenedor {
  padding-top: 70px;
  width: 100vw;
  max-width: 1920px; /*se limita a un maximo de 1920px de ancho*/
  height: 100vh;
  max-height: 1080px; /*se limita a un maximo de 1920px de ancho*/
  margin: 0 auto;
  text-align: center;
  color: darkblue;
  background-image: url("./img/fondo.jpg");
  border: red solid 2px;
}

.titulo {
  height: 10%;
  padding: 5px;
  font-size: 2rem;
  text-align: center;
}

.pagebody {
  width: 100%;
  height: 78%;
}



.formato {
  width: 50%;
  margin: 0px auto;
  border: darkblue 3px solid;
  border-radius: 1rem;
}

.fullentry {
  width: 50%;
  padding-top: 20px;
  text-align: left;
  margin: 0px auto;
}

.contenBoton {
  padding-top: 20px;
  padding-bottom: 20px;
}

.boton {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: darkblue;
  border: 1px solid darkblue;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.boton:hover {
  background-color: #787adb;
  border: 1px solid #000397;
}

.formlabel {
  margin-bottom: 0.5rem;
}

.formcontrol {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: darkblue;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid darkblue;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.pie_de_pagina {
  width: 100%;
  height: 10%;
  color: darkblue;
  font-size: small;
  border-top: darkblue 1px solid;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
 
}

.img {
  padding-left: 50px;
  padding-right: 50px;
}

@media screen and (max-width: 850px) {
  .contenedor {
    padding-top: 70px;
    width: 100vw;
    height: 100vh;
  }

  .pagebody {
    width: 100%;
    height: 80%;
  }

  .titulo {
    padding-top: 20px;
    height: 10%;
    font-size: 1.5rem;
    text-align: center;
  }

  .formato {
    width: 100%;
  }

  .fullentry {
    width: 90%;
  }

  .pie_de_pagina {
    width: 100%;
    height: 10%;
    align-items: center;
  }
}

@media screen and (max-width: 992px) {
  .contenedor {
    width: 100%;
  }

  .formato {
    width: 100%;
  }
}
