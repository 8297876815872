* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.contenido {
  width: 100%;
  /* height: 100vh; */
  padding-top: 100px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  background-image: url("./img/fondo.jpg");
}

.card {
  width: 30%;
  height: 365px;
  color: darkblue;
  border: blue 1px solid;
  border-radius: 5%;
  margin-bottom: 10px;
  padding-top: 10px;
}

.imagen_card {
  width: 100%;
  text-align: center;
}

.title_card {
  width: 100%;
  margin-bottom: 10px;
  text-align: center;
}

.descripcion_card {
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
  text-align: justify;
  color: cornflowerblue;
}

@media screen and (max-width: 850px) {
  .contenido {
    width: 100%;
    /* height: 2300px;*/
    padding-top: 90px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    background-image: url("./img/fondo.jpg");
  }
  .card {
    width: 100%;
    height: 400px;
  }

  .image_card {
    width: 90%;
    text-align: center;
  }
}
