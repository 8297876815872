* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.contenedorModal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
}

.modal {
  padding: 10px;
  width: 500px;
  min-height: 100px;
  background: #fff;
  position: relative;
  top: 40%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px;
}

.encabezadoModal {
  width: 100%;
  height: 25px;
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
}

.imagen {
  border-radius: 5px;
  border: black 1px solid;
}

.cerrarModal {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 8px;
  margin-right: 10px;
  border: none;
  transition: 0.3s ease all;
}

.cerrarModal:hover {
  cursor: pointer;
  opacity: 1;
}

.cerrarModal:active {
  box-shadow: 5px 2px rgba(0, 0, 0, 0.1);
  transform: translateY(4px);
}

.containerbutton {
  width: 100vw;
  height: 100px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.tituloModal {
  text-align: center;
}

.mensajeModal {
  text-align: center;
}

.containerBoton {
  width: 100%;
  display: flex;
  justify-content: center;
}

.boton {
  min-width: 80px;
  height: 36px;
  padding: 3px;
  border-radius: 3px;
  align-items: center;
  border: none;
  background: linear-gradient(
    180deg,
    rgba(13, 71, 161, 0.5) 0%,
    rgba(13, 71, 161, 1)
  );
  color: #ffffff;
  box-shadow: 5px 5px rgba(0, 0, 0, 0.1);
  font-weight: 500;
  text-align: center;
  align-items: center;
  transition: 0.3s ease all;
  opacity: 0.8;
}

.boton:hover {
  cursor: pointer;
  opacity: 1;
}

.boton:active {
  box-shadow: 5px 2px rgba(0, 0, 0, 0.1);
  transform: translateY(4px);
}
